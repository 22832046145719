function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from "react";
import globalScope from "./globalScope.js";
import { IfInitial, IfPending, IfFulfilled, IfRejected, IfSettled } from "./helpers.js";
import propTypes from "./propTypes.js";
import { neverSettle, actionTypes, init, dispatchMiddleware, reducer as asyncReducer } from "./reducer.js";
/**
 * createInstance allows you to create instances of Async that are bound to a specific promise.
 * A unique instance also uses its own React context for better nesting capability.
 */

export const createInstance = (defaultOptions = {}, displayName = "Async") => {
  const {
    Consumer,
    Provider
  } = React.createContext();

  class Async extends React.Component {
    constructor(props) {
      super(props);
      this.start = this.start.bind(this);
      this.load = this.load.bind(this);
      this.run = this.run.bind(this);
      this.cancel = this.cancel.bind(this);
      this.onResolve = this.onResolve.bind(this);
      this.onReject = this.onReject.bind(this);
      this.setData = this.setData.bind(this);
      this.setError = this.setError.bind(this);
      const promise = props.promise;
      const promiseFn = props.promiseFn || defaultOptions.promiseFn;
      const initialValue = props.initialValue || defaultOptions.initialValue;
      this.mounted = false;
      this.counter = 0;
      this.args = [];
      this.promise = neverSettle;
      this.abortController = {
        abort: () => {}
      };
      this.state = _objectSpread({}, init({
        initialValue,
        promise,
        promiseFn
      }), {
        cancel: this.cancel,
        run: this.run,
        reload: () => {
          this.load();
          this.run(...this.args);
        },
        setData: this.setData,
        setError: this.setError
      });
      this.debugLabel = props.debugLabel || defaultOptions.debugLabel;
      const {
        devToolsDispatcher
      } = globalScope.__REACT_ASYNC__;

      const _reducer = props.reducer || defaultOptions.reducer;

      const _dispatcher = props.dispatcher || defaultOptions.dispatcher || devToolsDispatcher;

      const reducer = _reducer ? (state, action) => _reducer(state, action, asyncReducer) : asyncReducer;
      const dispatch = dispatchMiddleware((action, callback) => {
        this.setState(state => reducer(state, action), callback);
      });
      this.dispatch = _dispatcher ? action => _dispatcher(action, dispatch, props) : dispatch;
    }

    componentDidMount() {
      this.mounted = true;

      if (this.props.promise || !this.state.initialValue) {
        this.load();
      }
    }

    componentDidUpdate(prevProps) {
      const {
        watch,
        watchFn = defaultOptions.watchFn,
        promise,
        promiseFn
      } = this.props;

      if (watch !== prevProps.watch) {
        if (this.counter) this.cancel();
        return this.load();
      }

      if (watchFn && watchFn(_objectSpread({}, defaultOptions, {}, this.props), _objectSpread({}, defaultOptions, {}, prevProps))) {
        if (this.counter) this.cancel();
        return this.load();
      }

      if (promise !== prevProps.promise) {
        if (this.counter) this.cancel();
        if (promise) return this.load();
      }

      if (promiseFn !== prevProps.promiseFn) {
        if (this.counter) this.cancel();
        if (promiseFn) return this.load();
      }
    }

    componentWillUnmount() {
      this.cancel();
      this.mounted = false;
    }

    getMeta(meta) {
      return _objectSpread({
        counter: this.counter,
        promise: this.promise,
        debugLabel: this.debugLabel
      }, meta);
    }

    start(promiseFn) {
      if ("AbortController" in globalScope) {
        this.abortController.abort();
        this.abortController = new globalScope.AbortController();
      }

      this.counter++;
      return this.promise = new Promise((resolve, reject) => {
        if (!this.mounted) return;

        const executor = () => promiseFn().then(resolve, reject);

        this.dispatch({
          type: actionTypes.start,
          payload: executor,
          meta: this.getMeta()
        });
      });
    }

    load() {
      const promise = this.props.promise;
      const promiseFn = this.props.promiseFn || defaultOptions.promiseFn;

      if (promise) {
        this.start(() => promise).then(this.onResolve(this.counter)).catch(this.onReject(this.counter));
      } else if (promiseFn) {
        const props = _objectSpread({}, defaultOptions, {}, this.props);

        this.start(() => promiseFn(props, this.abortController)).then(this.onResolve(this.counter)).catch(this.onReject(this.counter));
      }
    }

    run(...args) {
      const deferFn = this.props.deferFn || defaultOptions.deferFn;

      if (deferFn) {
        this.args = args;

        const props = _objectSpread({}, defaultOptions, {}, this.props);

        return this.start(() => deferFn(args, props, this.abortController)).then(this.onResolve(this.counter), this.onReject(this.counter));
      }
    }

    cancel() {
      const onCancel = this.props.onCancel || defaultOptions.onCancel;
      onCancel && onCancel();
      this.counter++;
      this.abortController.abort();
      this.mounted && this.dispatch({
        type: actionTypes.cancel,
        meta: this.getMeta()
      });
    }

    onResolve(counter) {
      return data => {
        if (this.counter === counter) {
          const onResolve = this.props.onResolve || defaultOptions.onResolve;
          this.setData(data, () => onResolve && onResolve(data));
        }

        return data;
      };
    }

    onReject(counter) {
      return error => {
        if (this.counter === counter) {
          const onReject = this.props.onReject || defaultOptions.onReject;
          this.setError(error, () => onReject && onReject(error));
        }

        return error;
      };
    }

    setData(data, callback) {
      this.mounted && this.dispatch({
        type: actionTypes.fulfill,
        payload: data,
        meta: this.getMeta()
      }, callback);
      return data;
    }

    setError(error, callback) {
      this.mounted && this.dispatch({
        type: actionTypes.reject,
        payload: error,
        error: true,
        meta: this.getMeta()
      }, callback);
      return error;
    }

    render() {
      const {
        children,
        suspense
      } = this.props;

      if (suspense && this.state.isPending && this.promise !== neverSettle) {
        // Rely on Suspense to handle the loading state
        throw this.promise;
      }

      if (typeof children === "function") {
        return React.createElement(Provider, {
          value: this.state
        }, children(this.state));
      }

      if (children !== undefined && children !== null) {
        return React.createElement(Provider, {
          value: this.state
        }, children);
      }

      return null;
    }

  }

  if (propTypes) Async.propTypes = propTypes.Async;

  const AsyncInitial = props => React.createElement(Consumer, null, st => React.createElement(IfInitial, _extends({}, props, {
    state: st
  })));

  const AsyncPending = props => React.createElement(Consumer, null, st => React.createElement(IfPending, _extends({}, props, {
    state: st
  })));

  const AsyncFulfilled = props => React.createElement(Consumer, null, st => React.createElement(IfFulfilled, _extends({}, props, {
    state: st
  })));

  const AsyncRejected = props => React.createElement(Consumer, null, st => React.createElement(IfRejected, _extends({}, props, {
    state: st
  })));

  const AsyncSettled = props => React.createElement(Consumer, null, st => React.createElement(IfSettled, _extends({}, props, {
    state: st
  })));

  AsyncInitial.displayName = `${displayName}.Initial`;
  AsyncPending.displayName = `${displayName}.Pending`;
  AsyncFulfilled.displayName = `${displayName}.Fulfilled`;
  AsyncRejected.displayName = `${displayName}.Rejected`;
  AsyncSettled.displayName = `${displayName}.Settled`;
  Async.displayName = displayName;
  Async.Initial = AsyncInitial;
  Async.Pending = AsyncPending;
  Async.Loading = AsyncPending; // alias

  Async.Fulfilled = AsyncFulfilled;
  Async.Resolved = AsyncFulfilled; // alias

  Async.Rejected = AsyncRejected;
  Async.Settled = AsyncSettled;
  return Async;
};
export default createInstance();